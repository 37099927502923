<template>
  <!-- DB商品维护 -->
  <div class="container">
  
      <div class="dataOper">
        <div class="OperInput">  
        <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div class="status" style="width: 356px;">
                  <div>维护时间</div>
                    <el-date-picker
                    v-model="datatime"
                    type="datetimerange"
                    range-separator="至"
                    :start-placeholder="startTime"
                    :end-placeholder="endTime"
                    format="yyyy-MM-dd"
                    @change="datebtn"
                    :clearable="true"
                    >
                    </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table
          style="font-family: PingFangRoutine"
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column  prop="storeName"  label="操作店铺"> </el-table-column>
          <el-table-column     label="操作人">
            <template slot-scope="scope">
              <span>{{scope.row.operator}}</span>
            </template>
          </el-table-column>
          <el-table-column  prop="createTime" label="操作时间"></el-table-column>
          <el-table-column  prop="goodsId"    label="商品ID"></el-table-column>
          <el-table-column  prop="name"       label="操作商品"></el-table-column>
          <el-table-column  width="590" label="操作记录">
            <template slot-scope="scope">
               <el-popover
                  placement="bottom"
                  trigger="hover"
                  width="450"
                  >
                  <div v-for="(item,index) in goodsDetailChangeLog(scope.row.goodsDetailChangeLog)" :key="index">
                    {{item}}
                  </div>
                  <div  style="text-overflow :ellipsis;white-space :nowrap;width: 580px;overflow: hidden;" slot="reference">
                     {{scope.row.goodsDetailChangeLog}}
                  </div>
                </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="queryinfo.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>

  </div>
</template>
<script>
import {bdGetGoods} from "@/api/bd";
import "@/font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
       currPage:1,//当前页码
       startTime:'',//开始时间
       endTime:'',//结束时间
       pageSize:10,//页面容量
       promoterId:'',//主键id
      },
      startTime:"",//input初始显示开始时间
      endTime:"",//input初始显示结束时间
      datatime:[],//日期数组
      total:0,//总条数
      tableData: [], // 表格数据
    };
  },
  activated() {
    this.date()
  },
  methods: {
    //商品记录然后数组格式
    goodsDetailChangeLog(item){
      if(item==null){
        item=''
      }
      let arr = item.split(';')
      return arr
    },
    //初始日期
    date(){
      let time1 = this.$route.query.startTime
      let time2 = this.$route.query.endTime
      let startTime = time1.indexOf(' ')
      let endTime = time2.indexOf(' ')
      this.startTime = time1.substring(0,startTime)
      this.endTime = time2.substring(0,endTime)
      this.queryinfo.promoterId=this.$route.query.id
      this.queryinfo.startTime=this.$route.query.startTime
      this.queryinfo.endTime=this.$route.query.endTime
      this.datatime[0]=this.$route.query.startTime
      this.datatime[1]=this.$route.query.endTime
      this.list()
    },
    //表格样式
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //BD商品维护列表
    list() {
        bdGetGoods(this.queryinfo).then(res=>{
          this.total = res.data.total * 1
          this.total == null?0:this.total
          this.tableData=res.data.list
        })
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    //选择日期确定
    datebtn(){
      this.total=0
      if (!this.datatime) { // 如果被清空，手动给它赋值为[]
          this.datatime = []
          this.queryinfo.startTime=this.$route.query.startTime
          this.queryinfo.endTime=this.$route.query.endTime
      }
      if(typeof this.datatime!=='string'&&this.datatime.length!==0) {
        let datatime1 = this.datatime[0]
        let newtime1 = datatime1.getTime()
        let item1 = new Date(newtime1)

        let datatime2 = this.datatime[1]
        let newtime2 = datatime2.getTime()
        let item2 = new Date(newtime2)

        let y1 = item1.getFullYear()
        let m1 = item1.getMonth()+1
        let d1 = item1.getDate()

        let y2 = item2.getFullYear()
        let m2 = item2.getMonth()+1
        let d2 = item2.getDate()
        let timeStr1 = y1 + '-' + m1+'-'+d1+' '+'0:00:00'
        let tiemStr2 = y2 + '-' + m2+'-'+d2+' '+'23:59:59'
         this.queryinfo.startTime=timeStr1,
         this.queryinfo.endTime=tiemStr2
      }
      this.list()
    }
  },
};
</script>
 <style lang="less" scoped>
@import "../../../../assets/css/common.less";
#btn {
  width: 90px;
  margin-right: 50px;
}
/deep/ #remark {
  height: 100px;
  width: 260px;
}
.el-textarea {
  height: 100px;
}
/deep/ .cell{
    text-align: center;
}
/deep/ .OperInput{
    display: flex;
    margin-top: -5px;
}
/deep/.el-table__body-wrapper::-webkit-scrollbar{
     /*width: 0;宽度为0隐藏*/
    width: 2px;
    height: 10px;
  }
  .head{
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 21px;
  }

</style>
